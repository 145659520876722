import React, {useContext, useState} from 'react';
import {navigate} from 'gatsby';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
  injectStripe
} from 'react-stripe-elements';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import UserContext from '../modules/UserContext';
import request from '../modules/request';
import Button from './Button';
import {Container, Form, Label} from './CardForm.styles';

const CardForm = ({stripe}) => {
  const [isLoading, setIsLoading] = useState(false);
  const user = useContext(UserContext);
  let path = '';

  // if we already have a card, we add a new one, otherwise we upgrade to paid plan
  if (user.cards[0]) {
    path = 'user/add-card/';
  } else if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);
    path = `user/change-plan/${urlParams.get('plan')}`;
  }

  const submit = async event => {
    event.preventDefault();
    setIsLoading(true);
    const {token} = await stripe.createToken();

    const response = await request(path, {cardToken: token && token.id});

    if (response.status === 200) {
      toast.success('Successfully added the card!', {
        position: toast.POSITION.TOP_CENTER,
        onClose: () => navigate('/dashboard/')
      });
    } else {
      const responseJSON = await response.json();
      toast.error(responseJSON.error, {
        position: toast.POSITION.TOP_CENTER
      });
    }

    setIsLoading(false);
  };

  const createOptions = () => ({
    style: {
      base: {
        fontSize: '14px',
        color: '#424770',
        letterSpacing: '0em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#9e2146'
      }
    }
  });

  return (
    <Container>
      <Form onSubmit={submit}>
        <Label>
          Card number
          <CardNumberElement {...createOptions()} />
        </Label>
        <Label>
          Expiration date
          <CardExpiryElement {...createOptions()} />
        </Label>
        <Label>
          CVC
          <CardCVCElement {...createOptions()} />
        </Label>
        <Label>
          Postal code
          <PostalCodeElement {...createOptions()} />
        </Label>
        <Button type="submit" disabled={isLoading}>
          Add Card
        </Button>
      </Form>
    </Container>
  );
};

CardForm.propTypes = {
  stripe: PropTypes.shape.isRequired
};

export default injectStripe(CardForm);
