import React, {useState, useEffect} from 'react';
import {Elements, StripeProvider} from 'react-stripe-elements';
import styled from 'styled-components';
import {Link} from 'gatsby';
import CardForm from '../components/CardForm';
import Layout from '../components/Layout';
import Card from '../components/Card';
import SectionHeader from '../components/SectionHeader';
import {pink} from '../modules/colors';
import media from '../modules/media';
import poweredByStripe from '../images/powered_by_stripe.svg';

const AddCardPage = () => {
  // those are used to initiallize stripe correctly for SSR (gatsby build)
  const [stripe, setStripe] = useState(null);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setStripe(window.Stripe(process.env.STRIPE_KEY));
    }
  }, []);

  return (
    <Layout>
      <StripeProvider stripe={stripe}>
        <div style={{marginBottom: 40}}>
          <SectionHeader>Add new card</SectionHeader>
          <Card>
            <Wrapper>
              <Elements>
                <CardForm />
              </Elements>
              <Sidebar>
                Your card details are securely handled by <a href="https://stripe.com">Stripe</a>.
                <br />
                <br />
                Once your card details are saved, please proceed to <Link to="/pricing">Pricing</Link> to select the
                plan you want.
              </Sidebar>
            </Wrapper>
            <PoweredByStripe src={poweredByStripe} />
          </Card>
        </div>
      </StripeProvider>
    </Layout>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.phone`flex-flow: wrap;`};
`;

const Sidebar = styled.div`
  width: 250px;
  padding: 20px 20px 40px 10px;
  font-size: 14px;

  > a {
    color: ${pink};
  }

  ${media.phone`width: 100%;`};
`;

const PoweredByStripe = styled.img`
  width: 149px;
  height: 41px;
`;

export default AddCardPage;
